








import PageHeader from '@/admin/components/PageHeader.vue'
import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import TaskType from '@/shared/modules/task-type/task-type.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import permissions from '@/shared/helpers/permissions.helper'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import TaskTypeService from '@/shared/modules/task-type/task-type.service'
import can from '@/shared/helpers/can.helper'
import TaskTypeDeletedFlag from '@/company/components/task-types/TaskTypeDeletedFlag.vue'
import Client from '@/shared/modules/client/client.model';
import router from '@/shared/router';
import User from '@/shared/modules/user/user.model';
import { AuthGetters } from '@/shared/store/auth/auth.getters';

@Component({
  components: { DataTable, PageHeader },
  methods: { __, can }
})
export default class TaskTypesIndex extends Vue {
  permissions = permissions
  table = new DataTableBase()
    .setModel(TaskType)
    .setEndpoint(`company/${ this.company.slug }/task-types`)
    .setDoubleClick(this.editTaskType)
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setText(__('company.views.task-types.index.table.columns.name'))
        .showIcon(TaskTypeDeletedFlag),
      new DataTableHeader()
        .setKey('amount')
        .setText(__('company.views.task-types.index.table.columns.amount')),
      new DataTableHeader()
        .setKey('tariff')
        .setText(__('company.views.task-types.index.table.columns.tariff')),
    ])
    .setActions([
      new EditAction()
        .setPermissions([
          permissions.company.taskTypes.show,
          permissions.company.taskTypes.update,
        ]),
      new DeleteAction()
        .setPermissions([
          permissions.company.taskTypes.delete,
        ])
        .setAction((workingGroup: TaskType) => this.$store.dispatch(GlobalActions.showDialog, {
          show: true,
          component: AreYouSureDialog,
          meta: {
            title: __('company.views.task-types.index.table.actions.delete.title'),
            text: __('company.views.task-types.index.table.actions.delete.text', { name: workingGroup.name }),
            onYes: () => TaskTypeService.destroy(this.company.slug, workingGroup.uuid)
              .then(() => this.tableRef.refresh())
          }
        }))
    ])

  get tableRef(): any {
    return this.$refs.table
  }

  navigateToCreate(): void {
    this.$router.push({ name: CompanyRoutes.taskTypesCreate })
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  editTaskType(taskType: TaskType) {
    if (taskType.hasDynamicPermissions([permissions.company.taskTypes.show, permissions.company.taskTypes.update])) {
      this.$router.push({ path: `${ router.currentRoute.path }/${ taskType.uuid }/edit`})
    }
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
