import Model from '@/shared/classes/model'
import { ITranslatable } from '@/shared/interfaces/classes/model.interfaces'
import Company from '@/shared/modules/company/company.model'
import ITaskTypeResponse from '@/shared/modules/task-type/task-type-response.interface'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'

export default class TaskType extends Model<IModelResponse> {
  name!: ITranslatable<string>|string
  description!: ITranslatable<string>|string
  amount!: number
  tariff!: number

  company_uuid!: string
  company!: Company

  transform(data: ITaskTypeResponse): void {
    super.transform(data)

    this.name = data.name
    this.description = data.description
    this.amount = data.amount
    this.tariff = data.tariff
    this.company_uuid = data.company_uuid
    this.company = this.setRelation(Company, data.company)
  }
}
